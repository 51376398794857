import details from './_details';
import forEach from './_ie11-forEach';
import includes from './_includes';

const polyfills = () => {
  details();
  forEach();
  includes();
};

export default polyfills;
