import generateHeadingIds from './_generateHeadingIds';
import generateNavCardLinks from './_generateNavCardLinks';
import generateStickyPageContents from './_generateStickyPageContents';
import showSwiftLoginBar from './_showSwiftLoginBar';
import replaceSwiftBySvg from './_replaceSwiftBySvg';
import dismissEmergencyBanner from './_dismissEmergencyBanner';
import gridSpaceController from './_gridSpaceController';
import toggleHomepageTabs from './_toggleHomepageTabs';
import hashtagExpandingAccordion from './_hashtagExpandingAccordion';

const customJS = () => {
  generateHeadingIds();
  generateNavCardLinks();
  generateStickyPageContents();
  showSwiftLoginBar();
  replaceSwiftBySvg();
  dismissEmergencyBanner();
  gridSpaceController();
  toggleHomepageTabs();
  hashtagExpandingAccordion();
};

export default customJS;
