const generateHeadingsIds = () => {
  // get all the titles inside RTE
  const titles = document.querySelectorAll(
    '.wmnds-grid div>h2:not(h2[class]), .wmnds-grid div>ol>li.h2, .wmnds-grid div>h3:not(h3[class]), .wmnds-grid div>h4:not(h4[class]), .wmnds-grid div>h5:not(h5[class]), .wmnds-grid div>h6:not(h6[class])'
  );

  titles.forEach((title) => {
    const newTitle = title;
    // generating and adding an id to the title
    const id = title.innerText.replace(/\s+/g, '-').toLowerCase();
    newTitle.id = id;

    return newTitle; // TODO: Check to make sure this still works
  });
};

export default generateHeadingsIds;
