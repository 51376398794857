const icons = () => {
  if (!document.querySelectorAll('.wmnds-hidden-icons').length) {
    // Ajax SVG in, SVGS are referenced in app (Icon component)
    const ajax = new XMLHttpRequest();
    ajax.open(
      'GET',
      'https://cloudcdn.wmca.org.uk/tfwmassets/ds/2.4.2/img/wmnds-icons.min.svg',
      true
    );
    ajax.send();
    ajax.onload = () => {
      const div = document.createElement('div');
      div.classList.add('wmnds-hidden-icons');
      div.style.display = 'none';
      div.innerHTML = ajax.responseText;
      document.body.insertBefore(div, document.body.childNodes[0]);
    };
  }
};

export default icons;
