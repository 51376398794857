const gridSpaceController = () => {
  const gridAreas = document.querySelectorAll('main>.wmnds-grid>div');
  gridAreas.forEach((gridArea) => {
    if (
      gridArea.querySelector('.wmnds-component') &&
      gridArea.querySelector('.wmnds-component').classList.contains('wmnds-rte')
    ) {
      gridArea.querySelector('.wmnds-component').classList.add('isFirstInThisPageArea');
    }
  });
};

export default gridSpaceController;
