const createTabElements = (jph, bth) => {
  const homepageTabs = document.querySelector('.wmnds-homepage-tabs');

  // First tab - JPH = Journey planner homepage
  const inputForJPH = document.createElement('input');
  inputForJPH.classList.add('wmnds-homepage-tabs__input', 'wmnds-screenreaders-only');
  inputForJPH.checked = 'checked';
  inputForJPH.type = 'radio';
  inputForJPH.value = 'Plan a journey';
  inputForJPH.name = 'WidgetType';
  inputForJPH.id = 'journey-planner';
  inputForJPH.ariaLabel = 'Plan a journey';

  const labelForJPH = document.createElement('label');
  labelForJPH.classList.add('wmnds-homepage-tabs__label');
  labelForJPH.htmlFor = 'journey-planner';
  labelForJPH.innerHTML = '<span>Plan a journey</span>';

  // Second tab - BTH = Buy a ticket homepage
  const inputForBTH = document.createElement('input');
  inputForBTH.classList.add('wmnds-homepage-tabs__input', 'wmnds-screenreaders-only');
  inputForBTH.type = 'radio';
  inputForBTH.value = 'Buy a ticket';
  inputForBTH.name = 'WidgetType';
  inputForBTH.id = 'buy-a-ticket';
  inputForBTH.ariaLabel = 'Buy a ticket';

  const labelForBTH = document.createElement('label');
  labelForBTH.classList.add('wmnds-homepage-tabs__label');
  labelForBTH.htmlFor = 'buy-a-ticket';
  labelForBTH.innerHTML = '<span>Buy a ticket</span>';

  // add tabs (input+label) to homepageTabs
  // remaining logic (selecting tab, showing/hiding widgets and h2, ..) is done with css
  homepageTabs.appendChild(inputForJPH);
  homepageTabs.appendChild(labelForJPH);
  homepageTabs.appendChild(inputForBTH);
  homepageTabs.appendChild(labelForBTH);

  // move widgets into homepageTabs div
  homepageTabs.appendChild(jph);
  homepageTabs.appendChild(bth);
};

const enableHomepageTabs = () => {
  // only runs if .wmnds-homepage-tabs div doesn't , in that case it will create it
  if (document.querySelector('.wmnds-homepage-tabs') == null) {
    const newDiv = document.createElement('div');
    newDiv.classList.add('wmnds-homepage-tabs');
    document.querySelector('main').prepend(newDiv);

    const jph = document.querySelector('.wmnds-journey-planner');
    const bth = document.querySelector('.wmnds-buy-a-ticket');
    if (jph && bth) {
      createTabElements(jph, bth);
    }
  }
};

const disableHomepageTabs = () => {
  const jph = document.querySelector('.wmnds-homepage-tabs > .wmnds-journey-planner');
  const bth = document.querySelector('.wmnds-homepage-tabs > .wmnds-buy-a-ticket');
  // only runs if .wmnds-homepage-tabs exists, that will be deleted after enter this if statement
  if (jph && bth) {
    const jphOriginalParent = document.querySelector(
      'main > .wmnds-col-1 > div.wmnds-grid > div > div > div'
    );
    const bthOriginalParent = document.querySelector(
      'main > .wmnds-col-1 > div.wmnds-grid > div + div > div > div'
    );
    jphOriginalParent.appendChild(jph);
    bthOriginalParent.appendChild(bth);
    document.querySelector('.wmnds-homepage-tabs').remove();
  }
};

const updateHomepageTabsStatus = () => {
  if (window.screen.width < 768) {
    enableHomepageTabs();
    return;
  }
  disableHomepageTabs();
};

const toggleHomepageTabs = () => {
  // if homepage
  if (window.location.pathname === '/') {
    updateHomepageTabsStatus();
    window.addEventListener('resize', updateHomepageTabsStatus);
  } else {
    window.removeEventListener('resize', updateHomepageTabsStatus);
  }
};

export default toggleHomepageTabs;
