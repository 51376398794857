// Run icon logic first; as components are dependant on them
import icons from './_icons';
import nIcons from './_n-icons';
// Then run everything else
import accordions from './_accordion';
import cookies from './_cookies';
import footer from './_footer';
import header from './_header';
import findATimetable from './_findATimetable';
import buyATicket from './_buyATicket';
import journeyPlanner from './_journeyPlanner';
import journeyPlannerWidget from './_journeyPlannerWidget';
import table from './_table';

const wmndsComponents = () => {
  icons();
  nIcons();
  accordions();
  cookies();
  footer();
  header();
  findATimetable();
  buyATicket();
  journeyPlanner();
  journeyPlannerWidget();
  table();
};

export default wmndsComponents;
