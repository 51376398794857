/* eslint-disable no-param-reassign */
const dismissEmergencyBanner = () => {
  const emergencyBanners = document.querySelectorAll('.wmnds-banner-container--emergency');

  const dismissBanner = (e) => {
    // gets information about the banner that was dismissed
    const emergencyBanner = e.target.closest('.wmnds-banner-container--emergency');
    const emergencyBannerTitle = emergencyBanner.querySelector('h2').innerText;
    const emergencyBannerType = emergencyBanner.querySelector('h2').dataset.id;

    // hides the banner
    emergencyBanner.style.display = 'none';

    // if global emergency banner type
    if (emergencyBannerType === 'global-emergency-banner-title') {
      localStorage.setItem('global-emergency-banner', emergencyBannerTitle);
    }

    // if page emergency banner type
    else {
      // if local storage variable already exist, add this page and title to the banners list
      const initialState = {
        banners: JSON.parse(localStorage.getItem('page-emergency-banner')) || [],
      };
      initialState.banners.push({ title: emergencyBannerTitle, page: window.location.href });
      localStorage.setItem('page-emergency-banner', JSON.stringify(initialState));
    }
  };

  emergencyBanners.forEach((emergencyBanner) => {
    // getting the details of this emergency banner
    const emergBannerHeading = emergencyBanner.querySelector('h2');
    let title = emergBannerHeading.innerText;
    while (title.charAt(0) === ' ') {
      title = title.slice(1);
    }
    const type = emergBannerHeading.dataset.id;

    // if it is an global emergency banner
    if (type === 'global-emergency-banner-title') {
      // search for global-emergency-banner-title var on local storage
      const localTitle = localStorage.getItem('global-emergency-banner');

      // if there is a var with that name and its value is equal to this emergency banner title that means that this user has already dismiss this emergency banner so it won't be shown again
      // which means that banner only shows if there are no local vars with that name or if localTitle exist but has a different title
      if (!localTitle || (localTitle && !title.includes(localTitle))) {
        emergencyBanner.style.display = 'block';
        // remove old emergency banner from local storage
        localStorage.removeItem('global-emergency-banner');
        // add event listeners to close
        emergencyBanner
          .querySelector('.wmnds-banner-container__emergency-close')
          .addEventListener('click', dismissBanner);
      }
    }

    // if it is an page specific emergency banner
    else if (type === 'page-emergency-banner-title') {
      // seacrh for page-emergency-banner-title var on local storage
      const localObject = JSON.parse(localStorage.getItem('page-emergency-banner'));

      // if it doesn't find the var it means that that any page emergency banner was dismissed and then banner will appear
      // or this page and this banner title is not on that list and then banner will appear
      if (
        !localObject ||
        (localObject &&
          localObject.banners.filter(
            (pageBanner) => pageBanner.title === title && pageBanner.page === window.location.href
          ).length === 0)
      ) {
        emergencyBanner.style.display = 'block';
        // if we are showing a different banner for a specific page we can remove the previous banner for this page as it is an old one
        const updatedBanners = localObject.banners.filter(
          (banner) => banner.page !== window.location.href
        );
        localStorage.setItem('page-emergency-banner', JSON.stringify(updatedBanners));
        // add event listeners to close
        emergencyBanner
          .querySelector('.wmnds-banner-container__emergency-close')
          .addEventListener('click', dismissBanner);
      }
    }
  });
};

export default dismissEmergencyBanner;
