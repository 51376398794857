import { cookieBanner, manageCookies, cookiePolicyLogic } from 'tfwm-cookies';

// Wait until everything is loaded
const cookiesJS = () => {
  cookieBanner();
  manageCookies();
  cookiePolicyLogic();
};

export default cookiesJS;
