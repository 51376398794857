const generateNavCardLinks = () => {
  const uls = document.querySelectorAll('.js-auto-page-content');

  if (uls && uls.length > 0) {
    uls.forEach((ul) => {
      const titles = document.querySelectorAll(
        '.wmnds-grid div>h2:not(h2[class]), .wmnds-grid div>h3:not(h3[class]), .wmnds-grid div>ol>li.h2, .wmnds-grid div>ul>li.h2'
      );
      titles.forEach((title) => {
        // creating the list item and anchor to add to the list
        const node = document.createElement('li');
        node.classList.add('wmnds-content-card__list-item');
        const link = document.createElement('a');
        link.href = `#${title.id}`;
        link.innerText = title.tagName === 'LI' ? title.childNodes[0].textContent : title.innerText;
        node.appendChild(link);
        ul.appendChild(node);
      });
    });
  }
};

export default generateNavCardLinks;
