const hashtagExpandingAccordion = () => {
  if (window.location.hash !== '') {
    const element = document.querySelector(window.location.hash);
    if (element && element.parentNode && element.parentNode.classList.contains('wmnds-accordion')) {
      element.parentNode.classList.add('wmnds-is--open');
      if (element.previousSibling) {
        element.previousSibling.ariaExpanded = true;
      }
      const topPos = element.parentNode.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: topPos, // scroll so that the element is at the top of the view
        behavior: 'smooth', // smooth scroll
      });
    }
  }
};

export default hashtagExpandingAccordion;
