const replaceSwiftBySvg = () => {
  // check if this page has purple banners
  const purpleBannerTitle = document.querySelector('.wmnds-branded-banner h1') !== null;
  if (purpleBannerTitle) {
    const title = document.querySelector('.wmnds-branded-banner h1');
    const swiftSVG = `<svg class="wmnds-swift-logo-inline">
                          <title>Swift</title>
                          <use xlink:href="#wmnds-swift-full-logo" href="#wmnds-swift-full-logo"></use>
                        </svg>`;
    const titleHTML = title.innerHTML.replace(/Swift/i, swiftSVG);
    title.innerHTML = titleHTML;
  }
};

export default replaceSwiftBySvg;
